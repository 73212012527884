import React, { createContext, useCallback, useEffect, useState } from 'react';

const isIOS = () => {
    // For browsers supporting navigator.userAgentData
    if (navigator.userAgentData) {
        if (
            navigator.userAgentData.platform &&
            navigator.userAgentData.brands
        ) {
            return navigator.userAgentData.platform === 'iOS';
        }
    }

    // Fallback for older browsers
    const userAgent = window.navigator.userAgent || navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

const getPushPhaseViews = () => {
    return parseInt(localStorage.getItem('pushViews') || 0);
};

// return true if prompting for push notifications is possible, otherwise false
const getCanPromptForPush = () => {
    const ios = isIOS();
    const isSafari =
        navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome');

    if (ios || isSafari || !('Notification' in window)) return false;

    return Notification?.permission === 'default';
};

const getIsShowPushPhase = () => {
    const canPromptForPush = getCanPromptForPush();

    if (!canPromptForPush) return false;
    return localStorage.getItem('showPushPhase') !== 'false';
};

// status can be 'default', 'called', 'granted' or 'denied'
const getPushStatus = () => {
    const canPromptForPush = getCanPromptForPush();
    if (!canPromptForPush) return 'denied';

    const status = localStorage.getItem('pushStatus');
    return status === 'granted' || status === 'denied' ? status : 'default';
};

const PushContext = createContext(null);

const PushNotificationProvider = ({ children }) => {
    const [showPushPhase, setShowPushPhase] = useState(() =>
        getIsShowPushPhase()
    );
    const [pushStatus, setPushStatus] = useState(() => getPushStatus());
    const [pushPhaseViews, setPushPhaseViews] = useState(() =>
        getPushPhaseViews()
    );
    const [pushPromptTotal, setPushPromptTotal] = useState(null);

    const updateShowPushPhase = useCallback(() => {
        const shouldShowPushPhase =
            (pushStatus === 'default' || pushStatus === 'called') &&
            pushPhaseViews < pushPromptTotal;

        setShowPushPhase(shouldShowPushPhase);
        localStorage.setItem('showPushPhase', shouldShowPushPhase.toString());
    }, [pushStatus, pushPhaseViews, pushPromptTotal]);

    useEffect(() => {
        !showPushPhase && localStorage.setItem('showPushPhase', 'false');
    }, [showPushPhase]);

    useEffect(() => {
        if (showPushPhase && pushPromptTotal) {
            updateShowPushPhase();
        }
    }, [pushPromptTotal, pushPhaseViews, pushStatus, updateShowPushPhase]);

    const updatePushPhaseViews = useCallback(() => {
        setPushPhaseViews((prevPushViews) => {
            const newPushViews = prevPushViews + 1;

            localStorage.setItem('pushViews', newPushViews.toString());
            return newPushViews;
        });
    }, []);

    const updatePushStatus = useCallback((pushStatus) => {
        setPushStatus(pushStatus);
        localStorage.setItem('pushStatus', pushStatus);
    }, []);

    return (
        <PushContext.Provider
            value={{
                pushStatus,
                showPushPhase,
                pushPhaseViews,
                setPushPromptTotal,
                setShowPushPhase,
                updatePushStatus,
                updatePushPhaseViews,
            }}>
            {children}
        </PushContext.Provider>
    );
};

export { PushNotificationProvider, PushContext };
